<template>
  <div>
    <div class="d-flex justify-space-between align-start lh-1 mb-4">
      <h5 class="mb-0">Como deseja pagar?</h5>
    </div>
    <div class="d-flex flex-column gap-4">
      <v-alert
        v-for="(paymentType, index) in availablePaymentMethods"
        :key="index"
        :outlined="selectedPaymentMethod === paymentType"
        :color="selectedPaymentMethod === paymentType ? 'primary' : 'info'"
        @click="selectedPaymentMethod = paymentType"
        style="cursor: pointer"
        rounded="lg"
        class="mb-0"
        text
      >
        <div class="d-flex gap-4 align-start">
          <div
            v-if="paymentTypes[paymentType].svg"
            style="color: #3f51b5 !important"
          >
            <div
              v-html="paymentTypes[paymentType].svg"
              style="width: 36px; height: 36px"
              :class="{
                'primary--text': selectedPaymentMethod === paymentType,
              }"
            ></div>
          </div>
          <v-icon
            v-else
            large
            :color="selectedPaymentMethod === paymentType ? 'primary' : 'info'"
          >
            {{ paymentTypes[paymentType].icon }}
          </v-icon>
          <div>
            <b>{{ paymentTypes[paymentType].name }}</b>
            <small v-if="paymentType === 'CREDIT_CARD'">
              até {{ maxInstallments[0] }}x
              {{ maxInstallments[1] ? "sem juros" : "com juros" }}
            </small>
            <p class="mb-0" v-if="paymentTypes[paymentType].description">
              {{ paymentTypes[paymentType].description }}
            </p>

            <v-card
              v-if="paymentTypes[paymentType].icons"
              class="d-flex flex-wrap gap-1 mt-1"
              color="transparent"
              elevation="0"
            >
              <img
                v-for="(icon, i) in paymentTypes[paymentType].icons"
                :key="i"
                :src="paymentMethodsIcons[icon]"
                :alt="icon"
                width="38px"
                height="22px"
                class="unselectable non-draggable white--text"
              />
            </v-card>
          </div>
        </div>
      </v-alert>
    </div>
    <div class="mt-4 d-flex flex-column gap-1">
      <v-btn
        @click="selectPaymentMethod"
        :disabled="!selectedPaymentMethod"
        color="primary"
        block
        depressed
      >
        Continuar
      </v-btn>
      <v-btn @click="$emit('close')" text small> Fechar </v-btn>
    </div>
  </div>
</template>

<script>
const icons = require.context("@/assets/images/payment-card/", false, /\.svg$/);
const pixImage = require("@/assets/images/payment-card/pix-icon.svg");

export default {
  data: () => ({
    selectedPaymentMethod: null,
    paymentTypes: {
      PIX: {
        name: "Pix",
        svg: null,
        icon: "mdi-qrcode",
        description: "Pague com o seu aplicativo de banco",
      },
      CREDIT_CARD: {
        icon: "mdi-credit-card",
        name: "Cartão de Crédito",
        icons: [
          "visa",
          "mastercard",
          "elo",
          "american-express",
          "hiper",
          "hipercard",
          // "discover",
          // "diners-club",
        ],
      },
      QR_CODE: {
        icon: "mdi-wallet",
        name: "Carteira Digital",
        icons: ["picpay", "mp", "bb", "bradesco", "caixa", "next"],
      },
    },
  }),

  methods: {
    selectPaymentMethod() {
      this.$emit("select", this.selectedPaymentMethod);
    },
    async loadPixIcon() {
      try {
        const response = await fetch(pixImage);
        this.paymentTypes.PIX.svg = await response.text();
      } catch (error) {
        console.error("Erro ao carregar o SVG:", error);
      }
    },
  },
  computed: {
    paymentMethodsIcons() {
      const iconsList = new Set(
        Object.values(this.paymentTypes).flatMap((pm) => pm.icons || [])
      );
      return Object.fromEntries(
        Array.from(iconsList).map((icon) => [icon, icons(`./${icon}.svg`)])
      );
    },
    maxInstallments() {
      return (this.payment.fees || []).reduce(
        (acc, fee) => {
          acc[0] = Math.max(acc[0], fee.installment);
          acc[1] = acc[1] && fee.interestFree;

          return acc;
        },
        [1, true]
      );
    },
  },
  mounted() {
    this.loadPixIcon();
  },

  props: {
    availablePaymentMethods: {
      type: Array,
      required: true,
    },
    payment: {
      type: Object,
    },
  },
};
</script>

<style>
</style>